import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import renderRichText from '@common/renderRichText';

import Navigation from '@core/Navigation';
import Seo from '@core/Seo';
import Image from '@core/Image';

import mq, { mqdown } from '@mq';
import { richText, hyphenate, linkHover } from '@mixins';

const PartnersPage = ({
  data: {
    contentfulPartnersPage: { partners },
  },
}) => (
  <>
    <Seo pageTitle="Beteiligte" />
    <Navigation />
    <Main>
      <List>
        {partners.map(partner => (
          <Partner key={partner.id} {...partner} />
        ))}
      </List>
    </Main>
  </>
);

const Partner = ({ title, description, image, link }) => (
  <PartnerContainer>
    <div>
      <h3>{title}</h3>
      <Description>{renderRichText(description)}</Description>
      {link && (
        <ExternalLink href={link} title={title} target="_blank" rel="noopener noreferrer">
          Link zur Webseite →
        </ExternalLink>
      )}
    </div>
    {link && (
      <ExternalLink href={link} title={title} target="_blank" rel="noopener noreferrer">
        <Logo image={image} />
      </ExternalLink>
    )}
    {!link && <Logo image={image} />}
  </PartnerContainer>
);

const Main = styled.main`
  padding-top: 6.375rem;

  ${mq.medium} {
    padding-top: 9.925rem;
  }
`;

const List = styled.ul`
  display: grid;
  row-gap: var(--space-double);

  ${mq.medium} {
    row-gap: 5rem;
  }
`;

const PartnerContainer = styled.li`
  display: grid;
  row-gap: 1rem;
  justify-items: start;

  > div {
    display: grid;
    row-gap: 1rem;

    ${mqdown.medium} {
      order: 1;
    }
  }

  ${mq.medium} {
    grid-template-columns: 1fr auto;
  }
`;

const Description = styled.div`
  ${richText};
  ${hyphenate};
`;

const Logo = styled(Image)`
  ${mq.medium} {
    width: 150;
  }
`;

const ExternalLink = styled.a`
  text-transform: uppercase;
  font: var(--font-small);
  ${linkHover};
`;

export const partnersPageQuery = graphql`
  query partnersPageQuery {
    contentfulPartnersPage {
      partners {
        id
        title
        description {
          raw
        }
        link
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 150
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            resizingBehavior: FILL
            quality: 80
          )
        }
      }
    }
  }
`;

export default PartnersPage;
